<!--
 * @Author: lzh
 * @Date: 2022-07-06 11:13:49
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-07 11:07:54
 * @Description: file content
-->
<template>
  <div class="edit-company-box">
    <div class="cell-b">
      <h3>主標題</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入標題-${lItem.name}`"
          @input="setValue([`${lItem.code=='HK'?'':lItem.code}title`])"
        ></el-input>
      </div>

      <h3>副標題</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          type="textarea"
          :placeholder="`請輸入副標題-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
        ></el-input>
      </div>

    </div>
    <div class="cell-b">
      <h3>描述內容
        <selfTips :content="$tipText.textWrap" />
      </h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}centerText`]"
          type="textarea"
          :placeholder="`請輸入描述內容-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}centerText`)"
        ></el-input>
      </div>
    </div>
    <div
      class="cell-b"
      v-for="(item,index) in configs.items"
      :key="index"
    >
      <h3>子標題</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="item[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入子標題-${lItem.name}`"
          @input="setValue('items')"
        ></el-input>
      </div>

      <h3>子內容</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="item[`${lItem.code=='HK'?'':lItem.code}contact`]"
          :placeholder="`請輸入子內容-${lItem.name}`"
          @input="setValue('items')"
        ></el-input>
      </div>
      <selfCell
        style="margin-top: 10px"
        title="選擇圖片"
      >
        <selfUpload
          v-model="item.img"
          type="default"
          @change="setValue('items')"
        />
      </selfCell>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  name: 'edit-company',
  mixins: [langEditMixin],
  props: {
    configs: {
      default () {
        return {
          title: '薑軟件有限公司',
          desc: 'Gingersoft Limited',
          items: [],
        };
      },
    },
    index: {
      default () {
        return 0;
      },
    },
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
  },
};
</script>

<style lang="less" scoped>
.edit-company-box {
}
</style>
